@import 'shared-web/styles/variables';

.sidebar {
  max-width: 252px;
  background-color: $white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: $xl;

  &_collapsed {
    max-width: 100px;
  }
}

.logo {
  width: 100%;
  min-width: 133px;
  height: auto;
  margin-bottom: 122px;
}

.item {
  margin-bottom: $s;
}

.line {
  background-color: $lighter_grey;
  margin: $xl 0;
}

.menu {
  all: unset;
  display: flex;
  align-items: center;
  text-decoration: none;
  width: 100%;
}

.collapse_icon {
  margin: 0 $s 0 $xxs;
}

.expand_icon {
  display: flex;
  justify-content: center;
  width: 100%;
}
