@import 'shared-web/styles/variables';

.spinner_container {
  position: relative;
  width: 48px;
  height: 48px;
  animation: spin 3s linear infinite;
}

.path {
  stroke: #5355a0;
  stroke-linecap: round;
  stroke-dasharray: 100;
  stroke-dashoffset: 0;
  animation: dash 1.5s ease-in-out infinite;
}

.background {
  stroke: $light_grey;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -125;
  }
}
