@import 'shared-web/styles/variables';

.add_person_button {
  padding: 0;
  margin-bottom: $m;
  background-color: transparent;

  span {
    color: $primary;
    text-decoration: underline;
  }
}

.header {
  width: 100%;
  justify-content: space-between;
  margin-bottom: $l;
}
