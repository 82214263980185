@import 'shared-web/styles/variables';

.display_name {
  width: 100%;

  &.is_manager {
    overflow: hidden;
    display: flex;
    flex-direction: row;

    .manager_display_name {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .manager_owner_icon {
      margin-left: $xs;
    }
  }

  .owner_display_name_container {
    max-width: 100%;
    position: relative;
    display: inline-flex;

    .owner_display_name {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.contact_person {
  & .cell {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
  }
}

.dropdown {
  padding: 0;
  margin: 0;
  width: 24px;
  height: 24px;

  .disabled {
    color: $silver;

    &:hover {
      background-color: transparent;
      color: $silver;
    }
  }
}

.full_width_cell {
  width: 100%;
}

.one_line_ellipsing {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
}
