@import 'shared-web/styles/variables';

.status {
  &_circle {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    min-width: 26px;
    height: 26px;
    border-radius: 13px;

    span {
      padding: 2px $xs;
    }
  }

  .status_silver {
    background-color: $status_silver;
  }

  .status_red {
    background-color: $status_red;
  }

  .status_orange {
    background-color: $status_orange;
  }

  .status_green {
    background-color: $status_green;
  }

  .status_blue {
    background-color: $status_blue;
  }
}
