@import 'shared-web/styles/variables';

.dialog {
  max-width: 660px;
}

.footer {
  display: flex;
  flex-direction: row-reverse;
  flex: 1;
  justify-content: space-between;
}

.title {
  margin-bottom: $l;
}

.row {
  display: flex;
  flex: 1;
}

.margin_right {
  margin-right: $l;
  flex: 1;
}

.label {
  margin-bottom: $s;
}

.read_only {
  padding: $m;
}

.status_row {
  display: flex;
  flex: 1;
  align-items: center;
  gap: $s;
}

.report_button {
  margin-right: $m;
}

.gutter {
  width: $xxl;
}

.separator {
  width: 100%;
  margin: $m 0 $l;
  border: solid 1px $silver;
}

.last_row {
  margin-bottom: -24px;
}

.last_row_read_only {
  margin-bottom: 0;
}
