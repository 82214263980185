@import 'shared-web/styles/variables';

.title {
  margin: $m 0 $m 0;
}

.container {
  display: flex;
}

.radio_button {
  position: absolute;
  z-index: -10;

  & + label {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    z-index: 1;
    cursor: pointer;
    border-top: 1px solid $primary;
    border-left: 1px solid $primary;
    border-bottom: 1px solid $primary;
    background-color: $grey;
    color: $dim;
    padding: $s;
    min-width: 90px;

    &:first-of-type {
      border-radius: 6px 0 0 6px;
    }

    &:last-of-type {
      border-radius: 0 6px 6px 0;
      border-right: 1px solid $primary;
    }
  }

  &:checked + label {
    color: $white;
    background-color: $primary;
  }
}
