@import 'shared-web/styles/variables';

.footer {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  align-items: center;
}

.row {
  display: flex;
  flex: 1;
  gap: $l;
}

.display_name {
  overflow: hidden;
  text-overflow: ellipsis;
}

.address {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 97%;
}

.label {
  margin-bottom: $s;
}

.account_balance_container {
  margin-bottom: 40px;
}
