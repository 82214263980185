@import 'shared-web/styles/variables';

.rental_unit {
  width: 100%;

  .tenants_names {
    max-width: 100%;
    position: relative;
    display: inline-flex;
  }

  .tenant_display_name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .given_reference {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    padding-right: 3px;
  }
}

.address {
  width: 100%;

  span {
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.status {
  padding-left: $xs;
}

.details {
  &_text {
    overflow: hidden;
    display: flex;

    .status {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.tooltip {
  --bs-tooltip-max-width: 100%;
}

.dropdown {
  padding: 0;
  margin: 0;
  width: 24px;
  height: 24px;

  .disabled {
    color: $silver;

    &:hover {
      background-color: transparent;
      color: $silver;
    }
  }
}
