@import 'shared-web/styles/variables';

.card {
  background-color: white;
  border-radius: 8px;
  width: 400px;
  margin-top: $xxl;
  display: flex;
  flex-direction: column;
  box-shadow: 0 10px 40px 0 rgb(0 0 0 / 20%);
}

.cardTop {
  margin: $xl $l $xl $l;
}

.input {
  height: 64px;
}

.input_password {
  margin-bottom: $s;
}

.button_container {
  display: flex;
}

.button {
  margin: $l;
  width: 100%;
}

.spinner {
  justify-self: center;
  align-self: center;
  margin: 5em;
}
