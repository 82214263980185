@import 'shared-web/styles/variables';

.card {
  background-color: white;
  border-radius: 8px;
  width: 400px;
  min-height: 500px;
  margin: $xxl 0 $l 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 10px 40px 0 rgb(0 0 0 / 20%);
}

.card_title {
  margin: $xl $l $xl $l;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input {
  height: $xxl2;
  margin-bottom: $s;
}

.button_container {
  display: flex;
}

.button {
  margin: $l;
  width: 100%;
}

.link {
  &:hover {
    color: $white;
  }
}

.terms_text {
  margin: 0 $l $l $l;
}
