@import 'shared-web/styles/variables';

.footer {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;

  .margin_right {
    margin-right: $m;
  }
}

.container {
  width: 660px;
}

.row {
  display: flex;
  flex: 1;
}

.info_container {
  display: flex;
  justify-content: space-between;
}

.info {
  width: 266px;
}

.info_title {
  margin: $l 0 $s 0;
}

.momo_id {
  align-self: flex-end;
  margin: $m 0 $xl 0;
}

.status {
  margin-right: $xs;
}

.next_image {
  width: 266px;
  height: 185px;
  background-color: $super_light_grey;
  border-radius: 4px;
}
