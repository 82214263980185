@import 'shared-web/styles/variables';

.content {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: 12px;
  max-width: 400px;
  text-align: center;
}
