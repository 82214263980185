@import 'shared-web/styles/variables';

$container_minimum_width: 800px;

.container {
  flex: 1 1 0%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.main {
  padding: $xl $xxl $xl $xxl;
  z-index: 10;
  flex: 1;
  height: 100%;
  overflow-y: scroll;
  overflow-x: auto;
  background-color: $primary_background;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-width: $container_minimum_width;
}

.content {
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 90%;
  min-width: $container_minimum_width;
  z-index: -1;
  flex: 1;
}

.footer {
  margin-top: $xxl2;
  min-width: $container_minimum_width;
}
