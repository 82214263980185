@import 'shared-web/styles/variables';

.dialog_header {
  margin: 0;
}

.dialog_content {
  margin: 0;
}

.info_container {
  text-align: left;
  padding: $xxl;
  border-radius: 4px 4px 0 0;
  background-color: $primary;
  background-image: url('./building.png');
  background-size: cover;
}

.header {
  font-size: 36px;
}

.content {
  margin: $xxl;
}

.text {
  margin-bottom: $l;
}

.footer {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}
