@import 'shared-web/styles/variables';

.information_text {
  margin-bottom: $m;
}

.link_container {
  display: flex;
  flex-direction: column;
}

.link {
  color: $dark_primary;
}
