@import 'shared-web/styles/variables';

.radio_container {
  width: 100%;
  background-color: $super_light_grey;
  height: 48px;
  border-radius: 6px;
  padding: 6px 6px 6px 5px;
  margin: $s 0 $l;
  display: flex;
  gap: $s;
  justify-content: center;
  align-items: center;
}

.radio_label {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &_selected {
    font-weight: bold;
    background-color: $primary;
    color: $white;
  }
}

.radio_button {
  display: none;
}
