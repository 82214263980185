@import 'shared-web/styles/variables';

.row {
  display: flex;
  flex: 1;
  gap: $l;
}

.separator {
  margin-top: $s;
}

.given_reference {
  margin-bottom: $l;
}
