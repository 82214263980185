@import 'shared-web/styles/variables';

.feedback_button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: $l;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: $dim;
  }

  .icon {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}
