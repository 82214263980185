@import 'shared-web/styles/variables';

.separator {
  width: 100%;
  margin: $m 0 $l;
  border: solid 1px $silver;
}

.switch_toggle {
  margin-top: $l;
}

.text_additional_data_toggle {
  margin-top: $m;
}

.highlight {
  // https://zpl.io/GEByLjW
  background-color: #f3e359;
  padding-left: 2px;
  padding-right: 2px;
}

.header_row {
  display: flex;
  justify-content: space-between;
  margin-bottom: $s;
  align-items: flex-end;
}

.checkbox {
  display: none;
}

.button {
  justify-content: left;
  margin: 0;
  padding: 0;
  background-color: transparent;
  align-items: flex-end;

  span {
    font-size: 12px;
    color: $primary;
    text-decoration: underline;
  }
}
