@import 'shared-web/styles/variables';

.spaced_row {
  display: flex;
  justify-content: space-between;
}

.circle {
  border-radius: 40px;
  height: 48px;
  width: 48px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 18px 0 $xs;
  padding: 6px $s 6px $s;
  background-color: $super_light_grey;
}

.verified {
  /* TODO: replace all colours with appropriately colored svg's */
  filter: invert(85%) sepia(0%) saturate(1139%) hue-rotate(339deg)
    brightness(98%) contrast(93%); /* $silver */
}

.alert {
  filter: invert(61%) sepia(89%) saturate(5971%) hue-rotate(353deg)
    brightness(96%) contrast(94%); /* $alert */
}

.success {
  filter: invert(54%) sepia(100%) saturate(299%) hue-rotate(79deg)
    brightness(98%) contrast(97%); /* $success */
}

.radio_group {
  display: flex;
  margin-left: -10px;
}
