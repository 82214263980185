@import 'shared-web/styles/variables';

.customContent {
  margin: 0;
}

.buttonWrapper {
  display: flex;
  justify-content: end;
  align-items: center;
  width: 100%;
}

.dialogTitle {
  font-weight: 700;
  display: flex;
  gap: $xs;
}

.accountData {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.title {
  padding-top: $m;
  border-top: 1px solid $lighter_grey;
}

.spinner {
  left: 0;
  right: 0;
  margin: auto;
  position: absolute;
}

.bold {
  font-weight: 700;
}

.counter {
  text-align: right;
  font-size: $s;
  color: $dim;
  margin-top: -30px;
  margin-bottom: $xxl;
}
