@import 'shared-web/styles/variables';

.filter_bar {
  display: flex;
  width: 100%;
  padding: $l 0;
  align-items: flex-start;
  gap: $s;

  // We are using the !important flag to ensure that the custom border style
  // takes precedence over any other styles that might be applied to it,
  // including those from the bootstrap library itself.
  button {
    border: none !important;
  }
}
