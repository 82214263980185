@import 'shared-web/styles/variables';

.row {
  display: flex;
  align-items: flex-end;
}

.spaced_row {
  display: flex;
  justify-content: space-between;
}

.footer {
  justify-content: space-between;
}

.icon {
  margin-right: $s;
}

.footer_text {
  margin: $m 0 $l 0;
}
