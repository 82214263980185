@import 'shared-web/styles/variables';

.container {
  display: flex;
  flex: 1;
  justify-content: space-between;
}

.row {
  display: flex;
  align-items: center;
}

.first_row {
  align-items: flex-start;
  margin-top: $l;
}

.input_row {
  display: flex;
  justify-content: space-between;
  margin: $l 0 $xxl 0;
}

.chip {
  border-radius: 21px;
  text-align: center;
  align-content: center;
  justify-content: center;
  margin: 0 18px 0 $xs;
  padding: 6px $s 6px $s;
  background-color: $primary;
}

.status {
  margin: 0 $xs 0 $s;
}

.length {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 5px;
  margin-bottom: $l;
}

.label {
  margin-right: $s;
}

.flex {
  flex: 1;
}

.tenant {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 35px;
}

.tenant_label {
  height: 100%;
}

.rental_ids {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.reference {
  margin-bottom: $xs;
}

.name {
  margin-bottom: $xxs;
}

.downlift {
  margin-bottom: -24px;
}

.line {
  background-color: $lighter_grey;
}

.last_row {
  margin-bottom: -20px;
}
