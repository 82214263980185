@import 'shared-web/styles/variables';

.backdrop {
  z-index: 1055; /* This is the z-index bootstrap puts the dialog on */
}

.container {
  margin: $xxl2 0;
  display: flex;
  justify-content: center;
  border: none;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: $xxl $xxl $l;
}

.header_icons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex: 1;
  margin-left: $xs;
}

.content {
  display: flex;
  flex-direction: column;
  margin: 0 $xxl $xxl;
}

.close {
  margin-left: $xs;
}

.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: $l $xxl $l $xxl;
  justify-content: space-between;
  border-radius: 0 0 4px 4px;
  background-color: $super_light_grey;
}

.dialog {
  background-color: $white;
  border-radius: 4px;
  max-width: 700px;
  box-shadow: rgb(0 0 0 / 11%) 0 0 22px;
}
