@import 'shared-web/styles/variables';

.dialog {
  background-color: $super_light_grey;
}

.customContent {
  margin: 0;
}

.title {
  font-weight: 100;
  display: flex;
  gap: $xs;
}

.accountData {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.spinner {
  left: 0;
  right: 0;
  margin: auto;
  position: absolute;
}

.basisangaben {
  display: flex;
  flex-direction: column;
  gap: $l;
}

.BasisangabenFooter {
  display: flex;
  justify-content: end;
}

.tabs {
  display: flex;
  justify-content: space-around;
  border-top: 1px solid $lighter_grey;
  background: $super_light_grey;
}

.tab {
  padding: $m $m;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  color: $black;

  &:hover {
    color: $black;
  }
}

.activeTab {
  position: relative;
  color: $black;
}

.activeTab::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  right: 0;
  height: 3px;
  background-color: $dark_primary;
}

.tabContent {
  padding: $xxl $xxl;
  margin: 0 -48px -48px;
  background: $white;
}

.bold {
  font-weight: 700;
}

.counter {
  text-align: right;
  font-size: $s;
  color: $dim;
  margin-top: -30px;
  margin-bottom: $l;
}
