@import 'shared-web/styles/variables';

.add_new_item_button {
  background-color: transparent;
  height: 68px;
  width: 100%;
  justify-content: flex-start;

  span {
    color: $primary;
    text-decoration: underline;
  }

  &:hover {
    background-color: $primary_shade_bright4;
  }
}
