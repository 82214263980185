@import 'shared-web/styles/variables';

.row {
  display: flex;
  align-items: center;
}

.tenant_first_name {
  margin-right: $l;
}

.footer {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}
