@import 'shared-web/styles/variables';

.iban_container {
  display: flex;
  justify-content: space-between;
  background-color: $super_light_grey;
  border-radius: 6px;
  padding: $m $s;
  border: 1px dashed $dim;
  margin-bottom: $l;
}

.label {
  margin-right: $s;
}

.iban {
  color: $dim;
}
