@import 'shared-web/styles/variables';

.row {
  display: flex;
  margin-top: $l;
}

.length {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: $l;
}

.title {
  margin: $m 0 $m 0;
}

.label {
  margin: $s 0 $s 0;
}

.column {
  width: 50%;
}

.line {
  color: $lighter_grey;
}
