@import 'shared-web/styles/variables';

.row {
  display: flex;
  flex: 1;
}

.hint_container {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-top: $m;

  .margin_right {
    margin-right: 5px;
  }
}

.primary_color {
  color: $primary;
}

.progress_bar {
  height: 6px;
  border-radius: 4px;
  background-color: $primary;
  width: 50%;
  position: relative;
  transition: width 1.5s ease;
  margin-top: $xxl;
}

.inactive_bar {
  height: 6px;
  border-radius: 4px;
  background-color: $separator;
  width: 100%;
  margin-top: -6px;
}
