@import 'shared-web/styles/variables';

.footer {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  align-items: center;
}

.gutter {
  width: $l;
}

.row {
  display: flex;
  flex: 1;
  gap: $l;
}

.header {
  margin-bottom: $l;
}

.details {
  display: flex;
  justify-content: space-between;
  margin-bottom: $xl;
}

.status_row {
  display: flex;
  flex: 1;
  gap: $s;
}

.spinner {
  left: 0;
  right: 0;
  margin: auto;
  position: absolute;
}

.text_button {
  display: flex;
  background-color: transparent;
  margin: $s 0 $l 0;
  border: 0;
  padding: 0;
}

.icon {
  margin-right: $s;
}

.margin_left {
  margin-left: $m;
}

.fake_disabled {
  background-color: $silver;
}
