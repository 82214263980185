@import 'shared-web/styles/variables';

.tile {
  padding: $l;
  display: grid;
  grid-template-columns: 1fr;
  gap: $s;
  background-color: $white;
}

.title {
  margin: 0;
}
