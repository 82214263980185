@import 'shared-web/styles/variables';

.card {
  background-color: white;
  border-radius: 8px;
  width: 400px;
  margin: $xxl 0 $l 0;
  display: flex;
  flex-direction: column;
  box-shadow: 0 10px 40px 0 rgb(0 0 0 / 20%);
}

.cardTop {
  margin: $xl $l $xl $l;
}

.input {
  height: $xxl2;
}

.input_email {
  margin-bottom: $s;
}

.button_container {
  display: flex;
}

.button {
  margin: $l;
  width: 100%;
}

.underline {
  text-decoration: underline;
}
