@import 'shared-web/styles/variables';

.container {
  width: 100%;
}

.heading {
  margin: $s 0;
}

.grid {
  margin: $l 0 $s 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: $l;
}
