@import 'shared-web/styles/variables';

.status {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  margin-bottom: $xl;
}

.actions {
  display: flex;
  flex-direction: row;
  gap: $l;
  margin-top: $xl;
  margin-bottom: $l;
}

.actions_button {
  width: 270px;
  height: 48px;
  justify-content: left;
  border-radius: 4px;
  border: solid 1px #cacaca;
}

.actions_dropdown_toggle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 12px 16px;

  svg {
    transition: all 200ms ease-in-out;
    transform: rotate(0deg);
  }

  &.rotate {
    svg {
      transform: rotate(-180deg);
    }
  }
}

.actions_dropdown_menu {
  width: 100%;
}

.row {
  display: flex;
  flex: 1;
  gap: $l;
}

.header {
  margin-bottom: $l;
}

.details {
  display: flex;
  justify-content: space-between;
  margin-bottom: $xl;
}

.status_row {
  display: flex;
  flex: 1;
  gap: $s;
}

.spinner {
  margin-left: 37%;
  position: absolute;
}

.deposit_iban_description {
  margin-bottom: $l;
}
