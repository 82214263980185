@import 'shared-web/styles/variables';

.toggle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: $l;
}

.account_icon {
  display: flex;
  justify-content: center;
  width: 100%;
}

.email_address {
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 150px;
}
