@import 'shared-web/styles/variables';

.container {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: flex-end;
  max-width: 700px;
  background: $white;
  border-radius: 4px;
}

.row {
  display: flex;
  flex: 1;
}

.back {
  margin-right: $s;
}

.footer {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}
