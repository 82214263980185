@import 'shared-web/styles/variables';

.nav_link {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.icon_container {
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
}

.selected {
  .icon_container {
    background-color: $primary;
  }

  .icon {
    filter: invert(100%) sepia(0%) saturate(2715%) hue-rotate(133deg)
      brightness(100%) contrast(100%);
  }
}

.label {
  margin-left: $xxs;
}
