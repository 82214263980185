@import 'shared-web/styles/variables';

.konto {
  .kontoTitle {
    font-weight: 700;
  }

  .kontoSubtitle {
    font-weight: 500;
    font-style: italic;
  }
}

.iban {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

.badge {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 8px;
  height: 14px;
  align-items: center;
  width: fit-content;
  color: $white;
  font-weight: 700;
  font-size: $xs;
  border-radius: 77px;
  background: var(--achromatic-medium-grey, #949496);
  text-transform: uppercase;
}

.saldo {
  font-weight: 700;
}
