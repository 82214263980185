@import 'shared-web/styles/variables';

.wrapper {
  margin-bottom: $m;
}

.row {
  display: flex;
  flex: 1;
  gap: $l;
}

.column_2 {
  display: flex;
  flex-direction: column;
  flex: 1;
  z-index: 10;
}

.country_container {
  flex: 1;
  align-self: stretch;
  margin-bottom: $xxl;
}

.label {
  margin-bottom: $s;
}

.country_select {
  cursor: pointer;
  border: none;
  appearance: none;
  height: 48px;
  background-color: $super_light_grey;
  border-radius: 6px;
  padding: $s $m $s $m;
  background-image: url('../../assets/chevron_down.png');
  background-size: $l $l;
  background-repeat: no-repeat;
  background-position: right $m top 50%;
  color: rgb(105 105 105);
}
