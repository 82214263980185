@import 'shared-web/styles/variables';

.table {
  width: 100%;
  display: grid;
  gap: $xxs 0;
  transition: all 200ms ease-in-out;

  .header_cell {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: $white;
    border: none;
    padding: $s $m;
    min-height: 48px;
    user-select: none;
    overflow: auto;

    svg {
      transition: all 200ms ease-in-out;
      transform: rotate(0deg);
    }

    &:focus {
      outline: none;
    }

    &.active {
      svg * {
        stroke: $black;
      }
    }

    &.active.desc {
      svg {
        transform: rotate(-180deg);
      }
    }
  }

  .grid_cell {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: $white;
    padding: $xs $m;
    min-height: 48px;
  }

  .row_start {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .row_end {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .loading_overlay {
    grid-column: 1/-1;
    grid-row: 2 / -1;
    display: flex;
    justify-content: center;
    padding-top: $xxl3;
    user-select: none;
    pointer-events: all;
    opacity: 1;
    background-color: rgba(255, 255, 255, 60%);
    backdrop-filter: blur(3px);
    border-radius: 4px;
    min-height: 300px;
  }

  .header_overlay {
    grid-column: 1/-1;
    grid-row: 1;
    user-select: none;
    pointer-events: all;
    z-index: 1;
  }
}

.margin_top {
  margin-top: $xxl3 * 2;
}

.empty_placeholder_wrapper {
  margin-top: $xxl3;
}

.placeholder_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: $xl;
}

.advanced_sorting_toggle {
  width: 100%;
}

.dropdown_header {
  padding-left: $l;
}

.dropdown_menu {
  width: 300px;
}

.dropdown_item {
  // This overrides the default dropdown height set in bootstrap.scss
  height: auto;
}

.filter_toggle {
  width: 100%;
}

.dropdown_item_filter {
  display: flex;
  justify-content: space-between;
  height: $xxl;
}

.dropdown_item_filter_checkbox {
  display: flex;
  margin-left: $m;
}

.dropdown_item_filter_dot {
  position: absolute;
  top: 0;
  right: 0;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: $primary;
}

.radio_label {
  p {
    color: $black;
  }
}

.loading_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $l;
}
