@import 'shared-web/styles/variables';

.container {
  display: flex;
}

.video_container {
  position: relative;
  width: 100vw;
  height: 100vh;
}

.video {
  display: flex;
  position: absolute;
  object-fit: cover;
  width: 100vw;
  height: 100vh;
}

.video_overlay {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: rgb(92 84 188 / 85%);
}

.content {
  padding: $xxl3 $xl 0 $xl;
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  align-items: center;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.help {
  margin: $xxl2 0 $m 0;
}

.logo {
  height: 64px;
  width: 64px;
  margin: $l 0;
}

.phone {
  text-decoration: none;
}

.phone:hover {
  color: inherit;
}
